import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly1B2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/events-activities/class-assembly/1B/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/class-assembly/1B/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/class-assembly/1B/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/class-assembly/1B/4.webp`;




  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },

  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >    
      <Typography variant='h4' align='center'>
       The Power of Colours	
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 1 B          Date: 03, 04 and 05 February 2025

        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  “Colours are the music of the eyes, creating harmony in our surroundings and filling our world with vibrant energy and emotion”
<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 1B presented a vibrant assembly on the fascinating topic “The Power of Colours” on 3rd, 4th and 5th February 2025. The assembly spread joy and positivity, highlighting how colours can influence our thoughts, emotions, and even decisions. 
 <br></br>
 The event began with a warm welcome, followed by a series of interesting facts and insights into how different colours can impact us in various ways. A wonderful dance performance by the children, showcased their incredible dancing skills. The dance performance was lively, energetic, and joyful.
 <br></br>
 The assembly concluded with thought that colours have the power to influence our emotions, behavior, and well-being thus shaping our environment and experiences.
  <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   COLOURS HAVE THE POWER TO BRIGHTEN OUR DAY, CALM OUR MIND, AND SPARK CREATIVITY.
   <br></br>
  
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly1B2024;